import ClientSheetUpdateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetUpdateBeneficiaryRequestResource";
import CorporationSheetCreateRequestResource from "common/resources/CorporationSheet/CorporationSheetCreateRequestResource";
import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import CorporationSheetUpdateRequestResource from "common/resources/CorporationSheet/CorporationSheetUpdateRequestResource";
import { ValidationError } from "common/resources/Resource";
import I18n from "components/materials/I18n";
import PageTemplateCreateClient, { IStep } from "components/PageTemplateCreateClient";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CorporationService from "services/CorporationService";
import { container } from "tsyringe";

import Beneficiaries, { groupsBeneficiaries } from "./Beneficiaries";
import classes from "./classes.module.scss";
import CorporationInfo, { groupsCorporationInfoCreate, groupsCorporationInfoEdit } from "./CorporationInfo";
import LegalRepresentative, { groupsLegalRepresentative } from "./LegalRepresentative";

export enum ESteps {
	CORPORATION_INFO = "CORPORATION_INFO",
	LEGAL_REPRESENTATIVE = "LEGAL_REPRESENTATIVE",
	BENEFICIARIES = "BENEFICIARIES",
}

type IProps = {
	isEdit?: boolean;
};

const corporationService = container.resolve(CorporationService);

export default function CreateCorporation(props: IProps) {
	const { isEdit } = props;
	const { corporationSheetId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const [completedSteps, setCompletedSteps] = useState<ESteps[]>([]);
	const [formDataCorporationInfo, setFormDataCorporationInfo] = useState<Partial<CorporationSheetResponseResource> | null>(null);
	const [formDataLegalRepresentative, setFormDataLegalRepresentative] = useState<Partial<CorporationSheetResponseResource> | null>(null);
	const [beneficiaries, setBeneficiaries] = useState<ClientSheetUpdateBeneficiaryRequestResource[]>([]);
	const [errorsCorporationInfo, setErrorsCorporationInfo] = useState<ValidationError[]>([]);
	const [errorsLegalRepresentative, setErrorsLegalRepresentative] = useState<ValidationError[]>([]);

	const isStep = useCallback((targetSteps: ESteps[]) => targetSteps.includes(searchParams.get("step") as ESteps), [searchParams]);

	useEffect(() => {
		window.scrollTo(0, 0);
		setSearchParams((prevSearchParams) => {
			const newSearchParams = new URLSearchParams(prevSearchParams);
			const step = newSearchParams.get("step") ?? ESteps.CORPORATION_INFO;
			newSearchParams.set("step", step);
			return newSearchParams;
		});
	}, [setSearchParams]);

	const setStep = useCallback(
		(step: ESteps) => {
			setSearchParams((prevSearchParams) => {
				const newSearchParams = new URLSearchParams(prevSearchParams);
				newSearchParams.set("step", step);
				return newSearchParams;
			});
		},
		[setSearchParams],
	);

	useEffect(() => {
		if (!isEdit || !corporationSheetId) return;
		if (searchParams.has(ESteps.CORPORATION_INFO) && searchParams.has(ESteps.LEGAL_REPRESENTATIVE) && searchParams.has(ESteps.BENEFICIARIES)) return;

		corporationService.getCorporationSheetById(corporationSheetId).then((corporationSheet) => {
			setSearchParams((prevSearchParams) => {
				const newSearchParams = new URLSearchParams(prevSearchParams);

				if (!newSearchParams.has(ESteps.CORPORATION_INFO)) {
					const data = JSON.stringify({ formData: hydrateCorporationInfo(corporationSheet, isEdit) });
					newSearchParams.set(ESteps.CORPORATION_INFO, data);
				}

				if (!newSearchParams.has(ESteps.LEGAL_REPRESENTATIVE)) {
					const data = JSON.stringify({ formData: hydrateLegalRepresentative(corporationSheet, isEdit) });
					newSearchParams.set(ESteps.LEGAL_REPRESENTATIVE, data);
				}

				if (!newSearchParams.has(ESteps.BENEFICIARIES)) {
					const data = JSON.stringify({ beneficiaries: corporationSheet.beneficiaries });
					newSearchParams.set(ESteps.BENEFICIARIES, data);
				}

				return newSearchParams;
			});
		});
	}, [corporationSheetId, isEdit, searchParams, setSearchParams]);

	useEffect(() => {
		const processSteps = async () => {
			const steps: ESteps[] = [];
			setErrorsCorporationInfo([]);
			setErrorsLegalRepresentative([]);
			const paramsCorporationInfo = searchParams.get(ESteps.CORPORATION_INFO);
			const paramsLegalRepresentative = searchParams.get(ESteps.LEGAL_REPRESENTATIVE);
			const paramsBeneficiaries = searchParams.get(ESteps.BENEFICIARIES);

			if (paramsCorporationInfo) {
				try {
					const formDataCorporationInfo = JSON.parse(paramsCorporationInfo).formData;
					const corporationInfoResource = hydrateCorporationInfo(formDataCorporationInfo, isEdit);
					setFormDataCorporationInfo(formDataCorporationInfo);
					await corporationInfoResource.validateOrReject?.({ groups: isEdit ? groupsCorporationInfoEdit : groupsCorporationInfoCreate });
					steps.push(ESteps.CORPORATION_INFO);
				} catch (error: unknown) {
					console.warn(error);
					if (Array.isArray(error)) setErrorsCorporationInfo(error);
				}
			}

			if (paramsLegalRepresentative) {
				try {
					const formDataLegalRepresentative = JSON.parse(paramsLegalRepresentative).formData;
					const legalRepresentativeResource = hydrateLegalRepresentative(formDataLegalRepresentative, isEdit);
					setFormDataLegalRepresentative(formDataLegalRepresentative);
					await legalRepresentativeResource.validateOrReject?.({ groups: groupsLegalRepresentative });
					steps.push(ESteps.LEGAL_REPRESENTATIVE);
					if (
						legalRepresentativeResource.representantLegal !== undefined &&
						beneficiaries.find((beneficiary) => beneficiary.id === legalRepresentativeResource.representantLegal!.id) === undefined
					)
						beneficiaries.push(
							ClientSheetUpdateBeneficiaryRequestResource.hydrate<ClientSheetUpdateBeneficiaryRequestResource>(legalRepresentativeResource.representantLegal, {
								groups: groupsBeneficiaries,
							}),
						);
				} catch (error: unknown) {
					console.warn(error);
					if (Array.isArray(error)) setErrorsLegalRepresentative(error);
				}
			}

			if (paramsBeneficiaries) {
				const beneficiaries = JSON.parse(paramsBeneficiaries).beneficiaries;
				CorporationSheetCreateRequestResource.hydratePartial<CorporationSheetCreateRequestResource>({ beneficiaries }, { groups: groupsBeneficiaries });
				setBeneficiaries(beneficiaries);
				steps.push(ESteps.BENEFICIARIES);
			}

			setCompletedSteps(steps);
		};

		processSteps();
	}, [isEdit, setStep, setSearchParams, searchParams, beneficiaries]);

	const steps: IStep[] = useMemo(
		() => [
			{
				title: I18n.asset.enums.ECorporationSteps[ESteps.CORPORATION_INFO],
				isActive: isStep([ESteps.CORPORATION_INFO]),
				onClick: () => setStep(ESteps.CORPORATION_INFO),
				isCompleted: completedSteps.includes(ESteps.CORPORATION_INFO),
			},
			{
				title: I18n.asset.enums.ECorporationSteps[ESteps.LEGAL_REPRESENTATIVE],
				isActive: isStep([ESteps.LEGAL_REPRESENTATIVE]),
				onClick: () => setStep(ESteps.LEGAL_REPRESENTATIVE),
				isCompleted: completedSteps.includes(ESteps.LEGAL_REPRESENTATIVE),
			},
			{
				title: I18n.asset.enums.ECorporationSteps[ESteps.BENEFICIARIES],
				isActive: isStep([ESteps.BENEFICIARIES]),
				onClick: () => setStep(ESteps.BENEFICIARIES),
				isCompleted: completedSteps.includes(ESteps.BENEFICIARIES),
			},
		],
		[completedSteps, isStep, setStep],
	);

	return (
		<PageTemplateCreateClient
			pageTitle={I18n.asset.pages.subscriptions.create_corporation.page_title}
			headerTitle={I18n.asset.pages.subscriptions.create_corporation.title}
			steps={steps}
			isEdit={isEdit ? { enabled: isEdit, corporationId: formDataCorporationInfo?.corporationId ?? "" } : undefined}>
			<div className={classes["root"]}>
				{searchParams.get("step") === ESteps.CORPORATION_INFO && <CorporationInfo formData={formDataCorporationInfo} errors={errorsCorporationInfo} isEdit={isEdit} />}
				{searchParams.get("step") === ESteps.LEGAL_REPRESENTATIVE && <LegalRepresentative formData={formDataLegalRepresentative} errors={errorsLegalRepresentative} />}
				{searchParams.get("step") === ESteps.BENEFICIARIES && (
					<Beneficiaries
						formDataCorporationInfo={formDataCorporationInfo}
						formDataLegalRepresentative={formDataLegalRepresentative}
						beneficiaries={beneficiaries}
						isEdit={isEdit}
					/>
				)}
			</div>
		</PageTemplateCreateClient>
	);
}

function hydrateCorporationInfo(formDataCorporationInfo: any, isEdit: boolean | undefined) {
	const data = {
		raisonSociale: formDataCorporationInfo.raisonSociale,
		siret: formDataCorporationInfo.siret,
		formeJuridique: formDataCorporationInfo.formeJuridique,
		formeJuridiqueAutre: formDataCorporationInfo.formeJuridiqueAutre,
		siegeSocial: {
			address: formDataCorporationInfo.siegeSocial.address,
			zipCode: formDataCorporationInfo.siegeSocial.zipCode,
			city: formDataCorporationInfo.siegeSocial.city,
			country: formDataCorporationInfo.siegeSocial.country,
		},
		residenceFiscale: formDataCorporationInfo.residenceFiscale,
		residenceFiscaleAutre: formDataCorporationInfo.residenceFiscaleAutre,
		regimeFiscal: formDataCorporationInfo.regimeFiscal,
		assujettiIFI: formDataCorporationInfo.assujettiIFI,
	};
	if (isEdit) {
		return CorporationSheetUpdateRequestResource.hydratePartial<CorporationSheetUpdateRequestResource>(
			{
				...data,
				id: formDataCorporationInfo.id,
				corporationId: formDataCorporationInfo.corporationId,
			},
			{
				groups: isEdit ? groupsCorporationInfoEdit : groupsCorporationInfoCreate,
			},
		);
	}

	return CorporationSheetCreateRequestResource.hydratePartial<CorporationSheetCreateRequestResource>(data, {
		groups: isEdit ? groupsCorporationInfoEdit : groupsCorporationInfoCreate,
	});
}

function hydrateLegalRepresentative(formDataLegalRepresentative: any, isEdit: boolean | undefined) {
	const data = {
		qualiteRepresentantLegal: formDataLegalRepresentative.qualiteRepresentantLegal,
		representantLegal: formDataLegalRepresentative.representantLegal,
	};

	if (isEdit) {
		return CorporationSheetUpdateRequestResource.hydratePartial<CorporationSheetUpdateRequestResource>(data, {
			groups: groupsLegalRepresentative,
		});
	}

	return CorporationSheetCreateRequestResource.hydratePartial<CorporationSheetCreateRequestResource>(data, {
		groups: groupsLegalRepresentative,
	});
}
