import React from "react";
import classes from "./classes.module.scss";
import classNames from "classnames";

export enum ITypoComponent {
	H1 = "h1",
	H2 = "h2",
	H3 = "h3",
	H4 = "h4",
	H5 = "h5",
	H6 = "h6",
	P = "p",
	SPAN = "span",
	DIV = "div",
}

type IProps = {
	typo: ITypo;
	children: React.ReactNode;
	color?: ITypoColor;
	className?: string | undefined;
	title?: string;
	style?: React.CSSProperties;
	component?: ITypoComponent;
	onClick?: () => void;
};

export enum ITypo {
	DISPLAY_LARGE = "display_large",
	DISPLAY_MEDIUM = "display_medium",
	H1 = "h1",
	H2 = "h2",
	P_LARGE = "p_large",
	P_LARGE_BOLD = "p_large_bold",
	P_MEDIUM = "p_medium",
	P_MEDIUM_BOLD = "p_medium_bold",
	P_SMALL = "p_small",
	P_SMALL_BOLD = "p_small_bold",
	CAPTION = "caption",
	CAPTION_BOLD = "caption_bold",
}

export enum ITypoColor {
	BLACK = "color-generic-black",
	WILD_SAND_950 = "Wild-Sand-950",
	WILD_SAND_50 = "Wild-Sand-50",
	WILD_SAND_500 = "Wild-Sand-500",
	WILD_SAND_600 = "Wild-Sand-600",
	WILD_SAND_700 = "Wild-Sand-700",
	WILD_SAND_900 = "Wild-Sand-900",
	WILD_SAND_800 = "Wild-Sand-800",
	WHITE = "color-generic-white",
	ERROR_500 = "Error-500",
	ERROR_800 = "Error-800",
	WARNING_600 = "Warning-600",
	WARNING_800 = "Warning-800",
	SUCCESS_800 = "Success-800",
}

export default function Typography({
	typo = ITypo.P_LARGE,
	children,
	color = ITypoColor.WILD_SAND_950,
	className,
	title,
	style,
	component = ITypoComponent.SPAN,
	onClick,
}: IProps) {
	return React.createElement(component, {
		className: classNames(classes["root"], classes[color], classes[typo], className),
		style,
		children,
		title,
		onClick,
	});
}
